import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import './App.css';
import video from './background.mp4';

function App() {
  useEffect(() => {
   mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {track_pageview: true, persistence: 'localStorage'}); 
  }, [])

  return (
    <div className="App">
      <video autoPlay loop muted className="video-background">
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <h1>Coming Soon</h1>
      </div>
    </div>
  );
}

export default App;
